import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import { colors, media } from '../styles/constants';
import { noVerticalPaddingStyle } from '../styles/layout';
import Social from './social';

const FooterContainer = styled.footer`
  background-color: ${colors.background};
  padding: 0.75rem 0;
  box-shadow: 0 1px 9px rgba(0, 0, 0, 0.35);
  transform: translateZ(1px);
`;

const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;

  @media (min-width: ${media.tabletPortrait}px) {
    justify-content: space-between;
  }

  ${noVerticalPaddingStyle};

  > div {
    flex: 1 0 100%;

    @media (min-width: ${media.tabletPortrait}px) {
      flex: 1 0 50%;

      &:last-child {
        justify-content: flex-end;
      }
    }
  }

  .gatsby-image-wrapper {
    width: 100%;
    max-width: 25rem;
    margin-bottom: 0.5rem;

    @media (min-width: ${media.tabletPortrait}px) {
      margin-bottom: 0rem;
    }
  }
`;

function Footer() {
  const p2020 = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "p2020.png" }) {
        childImageSharp {
          fluid(maxHeight: 50, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `);

  return (
    <FooterContainer>
      <ContentContainer>
        <Img
          fluid={p2020.placeholderImage.childImageSharp.fluid}
          imgStyle={{ height: 'auto' }}
        />
        <Social />
      </ContentContainer>
    </FooterContainer>
  );
}

export default Footer;
